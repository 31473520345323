import { convertDateFormat } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils'

export default class PromotionModel {
  constructor(){
    this.objRaw = {};
    this.id = null;
    this.tabStatus = '';
    this.status = '';
    this.start = '';
    this.end = '';
    this.code_name = '';
    this.is_code_name_diff = false;
    this.checked_code_name = '';
    this.price = '';
    this.is_max = false;
    this.maxCount = null;
    this.register_count = '0';
    this.use_count = '0';
    this.expired_count = '0';
    this.total_price = '0';
    this.memo = '';
    this.create_at = '';
    this.create_by = '';
  }
  setCodeNameDiffCheck(obj){
    const { is_code_name_diff, code_name } = obj;
    this.is_code_name_diff = is_code_name_diff;
    this.checked_code_name = code_name;
  }
  resetCodeNameDiffCheck(){
    this.is_code_name_diff = false;
    this.checked_code_name = '';
  }
  setData(obj){
    if( obj ) this.objRaw = obj;
    const { id, status, start, end, name, point, maxCount, register_count, use_count, expired_count, total_price, memo, createdAt, createdBy } = obj;
    this.id = id;
    this.status = status;
    this.start = start;
    this.end = end;
    this.code_name = name;
    this.price = point;
    this.is_max = maxCount != 0;
    this.maxCount = maxCount != 0 ? maxCount : null;
    // this.register_count = register_count;
    // this.use_count = use_count;
    // this.expired_count = expired_count;
    // this.total_price = total_price;
    this.memo = memo;
    this.create_at = createdAt;
    this.create_by = createdBy;
    // const diffCheck = {
    //   is_code_name_diff: true,
    //   code_name: code_name
    // }
    // this.setCodeNameDiffCheck(diffCheck)
  }
  getData(){
    const maxCount = this.maxCount ? Number(this.maxCount.replaceAll(',','')) : this.maxCount;
    let obj = {
      // id : this.id,
      name : this.code_name,
      start : convertDateFormat(this.start+' 00:00:00','YYYY-MM-DDTHH:mm:ss'),
      end : convertDateFormat(this.end+' 00:00:00','YYYY-MM-DDTHH:mm:ss'),
      couponType: 1,
      point : this.price,
      maxCount : Number(this.is_max ? maxCount : 0),
      // memo : this.memo,
    }
    return obj;
  }
}
<template>
  <PageWithLayout>
    <GroupBoxWidget v-if="viewModel.isModify">
      <BoxWidget
        title="쿠폰발행(코드등록)"
        :grid="20"
        :value="viewModel.model.register_count"
        unit="건"/>
      <BoxWidget
        title="사용"
        boxBgColor="#8fc0f9"
        :grid="20"
        :value="viewModel.model.use_count"
        :totalValue="viewModel.model.register_count"
        :isProgress="true"
        :isShowPercent="true"
        unit="건"/>
      <BoxWidget
        title="미사용"
        boxBgColor="#6CDFC3"
        :grid="20"
        :value="viewModel.model.register_count - viewModel.model.use_count - viewModel.model.expired_count"
        :totalValue="viewModel.model.register_count"
        :isProgress="true"
        :isShowPercent="true"
        unit="건"/>
      <BoxWidget
        title="만료"
        boxBgColor="#ffb1a6"
        :grid="20"
        :value="viewModel.model.expired_count"
        :totalValue="viewModel.model.register_count"
        :isProgress="true"
        :isShowPercent="true"
        unit="건"/>
      <BoxWidget
        title="총 보상금액"
        boxBgColor="#FFD82A"
        :grid="20"
        :value="viewModel.model.total_price"
        unit="원"/>
    </GroupBoxWidget>
    <TableView v-if="viewModel.isModify">
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">발행현황</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>발행상태</th>
          <td colspan="3">
            <span class="txt_tbl">
              <Badge
                badgeSize="small"
                :text="viewModel.model.status | convertIdToText('promotion_status')"
                :badgeStyle="viewModel.model.status | convertIdToColor('promotion_status')" />
            </span>
          </td>
        </tr>
        <tr>
          <th>발행일시</th>
          <td>{{ viewModel.model.create_at | convertDateFormat('YYYY-MM-DD HH:mm') }}</td>
          <th>발행인</th>
          <td>{{ viewModel.model.create_by }}</td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">가입 프로모션 코드 신규 발행</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>코드 입력 가능기간<IconSvg v-if="isCanEditAll" iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td v-if="isCanEditAll">
            <DateRange
              maxWidth="300px"
              :startValue.sync="viewModel.model.start"
              :endValue.sync="viewModel.model.end"
              :minDate="minDate"/>
            <p class="desc_info">💡 코드의 유효기간을 설정합니다. 대표는 기간내에서만 이 코드를 등록할 수 있습니다.</p>
          </td>
          <td v-else>{{ viewModel.model.start | convertDateFormat('YYYY-MM-DD') }}({{ viewModel.model.start | getDayToWeek }}) ~ {{ viewModel.model.end | convertDateFormat('YYYY-MM-DD') }}({{ viewModel.model.end | getDayToWeek }})</td>
        </tr>
        <tr>
          <th>코드 이름<IconSvg v-if="isCanEditAll" iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td v-if="isCanEditAll">
            <div class="group_form">
              <Input
                placeholder="코드 이름을 입력해주세요"
                maxWidth="300px"
                :maxLength="15"
                :isCount="true"
                :value.sync="viewModel.model.code_name"
                :valueType="viewModel.valid.code_name.valueType"
                :isError.sync="viewModel.valid.code_name.isValid"
                @update:value="value => viewModel.onUpdateCodeName(value)"/>
              <template v-if="!viewModel.model.checked_code_name || viewModel.model.checked_code_name != viewModel.model.code_name">
                <!-- <Button
                  btnSize="small"
                  btnStyle="secondary"
                  text="중복체크"
                  :disabled="!viewModel.model.code_name || viewModel.valid.code_name.isValid"
                  @onClickBtn="viewModel.onClickDiffCheck()"/> -->
              </template>
              <template v-else>
                <p
                  v-if="viewModel.model.is_code_name_diff"
                  class="desc_code_name_result tc_green">
                  <IconSvg iconName="check" :size="12" iconColor="#388E3B" iconDarkColor="#388E3B"/>
                  사용 가능한 코드 이름
                </p>
                <p
                  v-else
                  class="desc_code_name_result tc_red">
                  <IconSvg iconName="cross" :size="12" iconColor="#F04848" iconDarkColor="#F04848"/>
                  이미 사용중인 코드 이름
                </p>
              </template>
            </div>
            <p :class="['desc_info',{'tc_red' : viewModel.valid.code_name.isValid}]">💡 알파벳(대소문자 구분하지 않음), 숫자, 한글 음절로 15자까지 띄어쓰기 없이 입력 가능합니다.</p>
          </td>
          <td v-else class="td_point">
            <div v-if="viewModel.model.code_name" class="group_form">
              <span class="txt_tbl">{{ viewModel.model.code_name }}</span>
              <Button
                btnSize="small"
                btnStyle="secondary_border"
                text="복사하기"
                @onClickBtn="viewModel.onClickCopy()"></Button>
              </div>
          </td>
        </tr>
        <tr>
          <th>자녀 지급 보상금액<IconSvg v-if="isCanEditAll" iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td v-if="isCanEditAll">
            <Selectbox
              maxWidth="300px"
              :dataList="'promotion_price' | getSelectDataList"
              :value.sync="viewModel.model.price"
              :valueType="viewModel.valid.price.valueType"
              :isError.sync="viewModel.valid.price.isValid"
              :errorDesc="viewModel.valid.price.validAlertDesc"
              :showLine="6" />
          </td>
          <td v-else>{{ viewModel.model.price | convertNumberToComma(false,'0') }}원</td>
        </tr>
        <tr>
          <th>한도<IconSvg v-if="isCanEditAll" iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td v-if="isCanEditAll">
            <SwitchCheck
              :value.sync="viewModel.model.is_max"
              :isSwitchLeft="true"
              text="한도있음"
              offText="한도없음" />
            <template  v-if="viewModel.model.is_max">
              <div class="group_form">
                <Input
                  maxWidth="300px"
                  placeholder="한도를 입력해주세요"
                  :value.sync="viewModel.model.maxCount"
                  valueType="numberComma"
                  :isBlockUnValueType="true"/>
                <span class="txt_tbl">개</span>
              </div>
              <p class="desc_info">💡 0보다 큰 한도갯수를 입력해주세요</p>
            </template>
          </td>
          <td v-else>
            <template v-if="viewModel.model.maxCount">{{ viewModel.model.maxCount | convertNumberToComma(false,'0') }}개</template>
            <template v-else>한도없음</template>
          </td>
        </tr>
        <tr>
          <th>메모</th>
          <td>
            <Input
              placeholder="메모를 입력해주세요"
              :value.sync="viewModel.model.memo"/>
          </td>
        </tr>
      </template>
        <template v-slot:tbl_bottom>
          <ul class="list_info">
            <li>- 부모계정에서 코드를 입력하면, 자녀에게 양도 가능한 포인트 충전 쿠폰이 1장 지급됩니다.</li>
            <li>- 부모계정은 모든 가입 코드를 통틀어서 1번만 등록 가능하며, 모든 가입 코드는 가입후 30일 내에만 등록할 수 있습니다.</li>
            <li>- 코드입력으로 지급받은 쿠폰은 지급일로부터 30일 후에 만료되며, 부모는 본인의 그룹에 속한 자녀를 선택하여 쿠폰을 적용할 수 있습니다. </li>
            <li>- 자녀계정도 가입 프로모션 쿠폰은 1번만 적용받을 수 있습니다.</li>
          </ul>
        </template>
    </TableView>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="목록"
          routeName="SERVICE_PROMOTION_LIST"
          :routeQuery="{ status : viewModel.model.tabStatus, pageSize : $route.query.pageSize, page : $route.query.page }"/>
      </div>
      <div class="area_right">
        <Button
          v-if="isCanEditAll"
          btnSize="medium"
          btnStyle="fourthly"
          text="삭제"
          @onClickBtn="viewModel.onClickDelete()"/>
        <Button
          v-if="isCanExpired"
          btnSize="medium"
          btnStyle="fourthly"
          text="중단"
          @onClickBtn="viewModel.onClickExpire()"/>
        <Button
          v-if="viewModel.isModify"
          btnSize="medium"
          btnStyle="primary"
          text="수정"
          @onClickBtn="viewModel.onClickModify()"/>
        <Button
          v-else
          btnSize="medium"
          btnStyle="primary"
          text="발행"
          :disabled="isVaild"
          @onClickBtn="viewModel.onClickCompelete()"/>
      </div>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import GroupBoxWidget from '@lemontree-ai/lemontree-admin-common-front/components/common/boxInfo/GroupBoxWidget';
import BoxWidget from '@lemontree-ai/lemontree-admin-common-front/components/common/boxInfo/BoxWidget';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import DateRange from '@lemontree-ai/lemontree-admin-common-front/components/common/datePicker/DateRange';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import SwitchCheck from '@lemontree-ai/lemontree-admin-common-front/components/common/switch/SwitchCheck';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Badge from '@lemontree-ai/lemontree-admin-common-front/components/common/badge/Badge';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import { convertDateFormat, calcDate } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils'
// viewModel
import PromotionDetailViewModel from '@/views/service/promotion/viewModel/PromotionDetailViewModel'

export default {
  name:'PromotionRegister',
  components:{
    PageWithLayout,
    TableView,
    GroupBoxWidget,
    BoxWidget,
    IconSvg,
    DateRange,
    Selectbox,
    SwitchCheck,
    Input,
    Badge,
    Sticky,
    Button,
  },
  beforeMount(){
    const id = this.$route.params.id;
    const status = this.$route.params.status;
    this.viewModel.init(id,status);
    this.viewModel.isModify = this.$route.meta.isModify;
  },
  data(){
    return{
      viewModel: new PromotionDetailViewModel(),
    }
  },
  computed:{
    minDate(){
      return convertDateFormat(new Date(), 'YYYY-MM-DD')
    },
    isCanExpired(){
      return this.viewModel.isModify && this.viewModel.model.status === 'ING'
    },
    isCanEditAll(){
      return !this.viewModel.isModify || ( this.viewModel.isModify && this.viewModel.model.status === 'PLAN' )
    },
    isVaild(){
      return this.viewModel.registerValidatedResult()
    }
  }
}
</script>
<style scoped>
.desc_code_name_result{margin-left:8px;font-weight:600;font-size:14px}
.desc_code_name_result .icon_firfin{margin:12px 2px 0 0}

.switch_comm{display:block}
.switch_comm + .group_form{margin-top:4px}
</style>
import PromotionModel from '@/views/service/promotion/model/PromotionModel';
import { pageValidated } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils'
import router from '@/router';
import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

export default class AlarmDetailViewModel {
  constructor() {
    this.model = new PromotionModel();
    this.isModify = false;
    this.valid = {
      start:{
        value: 'start',
        valueType:'',
        isValid:false,
        validAlertDesc: '코드 입력 가능기간을 입력해주세요',
      },
      end:{
        value: 'end',
        valueType:'',
        isValid:false,
        validAlertDesc: '코드 입력 가능기간을 입력해주세요',
      },
      code_name:{
        value: 'code_name',
        valueType:'promotionCode',
        isValid:false,
        validAlertDesc: '알파벳, 숫자, 한글로 2자이상 15자 이하로 입력해주세요',
      },
      price:{
        value: 'price',
        valueType:'',
        isValid:false,
        validAlertDesc: '자녀에게 지급할 보상금액을 입력해주세요',
      },
      // maxCount:{
      //   value: 'maxCount',
      //   valueType:'number',
      //   isValid:false,
      //   vIf:{
      //     valueName:'is_max',
      //     value:true
      //   },
      //   validAlertDesc: '한도를 입력해주세요',
      // },
    }
  }

  init(id,status){
    if(id){
      this.model.tabStatus = status;
      this.getPromotionDetail(id)
    }
  }

  registerValidatedResult(){
    let maxValid = false;
    if(this.model.is_max){
      const maxCount = this.model.maxCount ? Number(this.model.maxCount.replaceAll(',','')) : this.model.maxCount;
      maxValid = !(maxCount > 0)
    }
    return pageValidated(this.valid, this.model) || maxValid
  }
  onUpdateCodeName(){
    this.model.resetCodeNameDiffCheck()
  }
  onClickCopy(){
    const copyData = this.model.code_name;
    window.navigator.clipboard.writeText(copyData).then(() => {
      store.dispatch('commonToast/fetchToastStart', `프로모션 코드가 복사되었습니다`);
    });
  }
  onClickDelete(){
    const deleteOk = () => {
      store.dispatch('commonToast/fetchToastStart', `프로모션 코드가 삭제되었습니다`);
      router.push({ name: 'SERVICE_PROMOTION_LIST' });
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `<strong>예약된 프로모션 코드를 삭제할까요?</strong>마케팅 채널에 이미 배포되지 않았는지<br>제대로 확인 후 삭제해 주세요.`,
      closeBtnText: '취소',
      compeleteBtnText: '삭제하기',
      confirmCallBack: deleteOk,
    });
  }
  onClickExpire(){
    const expireOk = () => {
      store.dispatch('commonToast/fetchToastStart', `프로모션 코드가 중단되었습니다`);
      router.push({ name: 'SERVICE_PROMOTION_LIST' });
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `<strong>경고! 이 프로모션을 정말 중단 할까요?</strong>예정된 입력기한은 ${this.model.date[1]} 입니다.<br>중단 시 '${this.model.code_name}'코드는<br>지금 즉시 입력불가 상태로 전환됩니다.<br>계획과 다른 일정에 CS 발생 가능성이 있으니<br>충분히 공지 후 진행해 주세요.`,
      closeBtnText: '취소',
      compeleteBtnText: '중단하기',
      confirmCallBack: expireOk,
    });
  }
  onClickModify(){
    store.dispatch('commonToast/fetchToastStart', `프로모션 코드 수정이 완료되었습니다.`);
    // router.push({ name: 'SERVICE_PROMOTION_MODIFY', params: { id : this.model.id } });
  }  
  onClickCompelete(){
    this.postPromotionDetail();
  }

  // 상세
  getPromotionDetail(id){
    const path = `${apiPath.COUPON_DETAIL.format(id)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.model.setData(resultData);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // 등록
  postPromotionDetail(){
    const path = `${apiPath.COUPON_REGISTER}`;
    const data = this.model.getData();
    POST_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', `프로모션 코드 발행이 완료되었습니다.`);
      router.push({ name: 'SERVICE_PROMOTION_LIST' });
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}